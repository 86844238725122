<template>
  <div>
    <NavigationBar showBackBtn titel="Kalender einbinden"></NavigationBar>
    <v-snackbar v-model="snackbar.model" multi-line timeout="2000"
      >{{ snackbar.text }}
    </v-snackbar>
    <section>
      <v-container v-if="!checkFeatureAvability">
        <v-alert
          type="warning"
          border="left"
          colored-border
          icon="mdi-alert"
          elevation="2"
          class="mt-6"
        >
          <div class="title">Hinweis</div>
          <div class="mt-2">
            Kalenderfeeds sind für diese Organisation nicht verfügbar.
            Kontaktiere den Administrator, um die Funktion zu aktivieren.
          </div>
        </v-alert>
      </v-container>
      <v-container v-else>
        <v-row justify="center" align="center">
          <v-col cols="12"
            ><v-card>
              <v-card-title>
                Veranstaltungen in eigenen Kalender einbinden
              </v-card-title>
              <v-card-text>
                <div class="subtitle-1 pb-4">
                  Veranstaltungen der Organisation können in den eigenen
                  Kalender eingebunden werden. Sie werden in regelmäßigen
                  Abständen aktualisiert. Änderungen an Veranstaltungen werden
                  jedoch mit kurzer Verzögerung und nicht in Echtzeit im
                  Kalender angezeigt. Zur Einbindung muss ein Kalenderfeed
                  angefordert werden und der angezeigte Link als
                  Kalenderabonnement im persönlichen Kalender hinzugefügt
                  werden.
                </div>
              </v-card-text></v-card
            >
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-data-iterator
              :items="calendarFeeds"
              item-key="meta.id"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.meta.id"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-card>
                      <v-card-text>
                        <v-text-field
                          v-model="item.subscriptionUrl"
                          outlined
                          label="Link für Kalenderfeed"
                          readonly
                          filled
                          prepend-inner-icon="mdi-rss"
                        >
                        </v-text-field>
                        <v-btn
                          depressed
                          @click="copyToClipboard(item.subscriptionUrl)"
                          ><v-icon left>mdi-content-copy</v-icon>Link in die
                          Zwischenablage kopieren</v-btn
                        >
                        <v-btn
                          class="ml-2"
                          depressed
                          text
                          color="error"
                          :loading="loadingDeleteFeed"
                          @click="deleteCalendarFeed(item.meta.id)"
                        >
                          <v-icon left>mdi-delete</v-icon>Kalenderfeed löschen
                        </v-btn></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card>
                  <v-card-text>
                    Es wurde noch kein persönlicher Kalenderfeed erstellt.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      depressed
                      color="primary"
                      :loading="loadingRequestFeed"
                      @click="requestCalendarFeed"
                      class="ml-2 mb-2"
                    >
                      <v-icon left>mdi-rss</v-icon>Kalenderfeed anfordern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" v-if="checkIfSupport">
          <v-col cols="12">
            <div class="mt-7">
              <v-btn
                depressed
                color="error"
                @click="regenerateCalendarFeeds()"
                :loading="loadingFixRequest"
                ><v-icon small left>mdi-wrench</v-icon>Fehlerbehebung: Daten für
                Kalenderfeeds neu generieren</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { functions } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { GET_CALENDAR_FEEDS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar";

export default {
  name: "scheduling-calendar-feeds",
  components: {
    NavigationBar,
  },
  data() {
    return {
      loadingRequestFeed: false,
      loadingDeleteFeed: false,
      loadingFixRequest: false,
      snackbar: {
        model: false,
        text: "",
      },
      feed: {
        url: "",
      },
    };
  },
  computed: {
    checkFeatureAvability() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "allowCalendarFeeds"
      );
    },
    calendarFeeds() {
      return this.$store.state.scheduling.calendarFeeds;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
  },
  created() {
    if (this.checkFeatureAvability) {
      this.getCalendarFeeds();
    }
  },
  methods: {
    getCalendarFeeds() {
      this.$store.dispatch(`${SCHEDULING}${GET_CALENDAR_FEEDS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    requestCalendarFeed() {
      this.loadingRequestFeed = true;
      const callFunction = functions.httpsCallable(
        "scheduling-requestCalendarFeed"
      );
      callFunction({ organizationId: this.$route.params.organizationId })
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            this.snackbar.text = "Kalenderfeed wurde generiert";
            this.snackbar.model = true;
            this.getCalendarFeeds();
            this.loadingRequestFeed = false;
          } else {
            this.snackbar.text = "Fehler: Versuche es später erneut.";
            this.snackbar.model = true;
            this.loadingRequestFeed = false;
          }
        })
        .catch((err) => {
          // handle error
          console.error(err.errorMsg);
          console.error(err);
          this.snackbar.model = true;
          this.loadingRequestFeed = false;
        });
    },
    regenerateCalendarFeeds() {
      this.loadingFixRequest = true;
      const callFunction = functions.httpsCallable(
        "scheduling-regenerateCalendarFeeds"
      );
      callFunction({
        organizationId: this.$route.params.organizationId,
      })
        .then((result) => {
          console.log(result.data);
          this.snackbar.text =
            "Die Daten für Kalenderfeeds wurden erfolgreich neu generiert. Es kann bis zu 24 Stunden dauern, bis Änderungen sichtbar werden.";
          this.snackbar.model = true;
          this.getCalendarFeeds();
          this.loadingFixRequest = false;
        })
        .catch((err) => {
          // handle error
          console.error(err.errorMsg);
          console.error(err);
          this.snackbar.model = true;
          this.loadingFixRequest = false;
        });
    },
    deleteCalendarFeed(feedId) {
      this.loadingDeleteFeed = true;
      const callFunction = functions.httpsCallable(
        "scheduling-deleteCalendarFeed"
      );
      callFunction({
        organizationId: this.$route.params.organizationId,
        feedId: feedId,
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            this.snackbar.text = "Kalenderfeed wurde gelöscht";
            this.snackbar.model = true;
            this.getCalendarFeeds();
            this.loadingDeleteFeed = false;
          } else {
            this.snackbar.text = "Fehler: Versuche es später erneut.";
            this.snackbar.model = true;
            this.loadingDeleteFeed = false;
          }
        })
        .catch((err) => {
          // handle error
          console.error(err.errorMsg);
          console.error(err);
          this.snackbar.model = true;
          this.loadingDeleteFeed = false;
        });
    },
    copyToClipboard(link) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", link);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = link;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Fehler beim Kopieren in Zwischenablage.", ex);
          this.snackbar.text = "Fehler beim Kopieren in Zwischenablage!";
          this.snackbar.model = true;
          return false;
        } finally {
          document.body.removeChild(textarea);
          this.snackbar.text = "Link erfolgreich in Zwischenablage kopiert!";
          this.snackbar.model = true;
        }
      }
    },
  },
};
</script>

<style></style>
