<template>
  <div class="tipp-card mt-6" v-if="alert">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-alert
            :outlined="outlined"
            :elevation="elevation"
            :border="border"
            :colored-border="coloredBorder"
            :color="color"
            :type="type"
            :icon="icon"
            transition="scale-transition"
          >
            <div class="title mb-2">
              {{ titel }}
            </div>
            <slot name="content">
              <div class="body-2">{{ text }}</div>
            </slot>
            <!-- <v-divider
                  class="my-4 primary"
                  style="opacity: 0.22"
                ></v-divider> -->
            <v-row align="center" no-gutters>
              <!-- <v-col class="grow">
                    {{ actionText }}
                  </v-col> -->
              <v-spacer></v-spacer>
              <v-col class="shrink">
                <v-btn
                  :outlined="outlined"
                  :color="color"
                  depressed
                  class="mt-3"
                  @click="dismissAlert(hintID)"
                  :loading="btnLoading"
                >
                  {{ btnText }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { db, auth, FieldValue } from "@/firebase";
import { USER } from "@/store/modules.js";
import { GET_USER_PROFILE } from "@/store/action-types.js";
export default {
  name: "tipp-card",
  props: {
    hintID: {
      type: String,
      required: true,
    },
    titel: {
      type: String,
      default: "Hinweis",
    },
    text: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "Verstanden",
    },
    // Props v-alert
    icon: {
      type: String,
    },
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "info",
      validator: function (value) {
        // Value must match one of these strings
        return (
          ["", "success", "warning", "info", "error"].indexOf(value) !== -1
        );
      },
    },
    border: {
      type: String,
      default: "left",
      validator: function (value) {
        // Value must match one of these strings
        return ["", "left", "right", "top", "bottom"].indexOf(value) !== -1;
      },
    },
    coloredBorder: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      alert: true,
      btnLoading: false,
    };
  },
  computed: {
    hints() {
      return this.$store.state.user.user.uiConfig.hints;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.check(this.hintID);
    },
    check(id) {
      var input = this.hints;
      if (input.includes(id) || input.includes("hidden")) {
        this.alert = false;
      } else {
        this.alert = true;
      }
    },
    dismissAlert(hintID) {
      this.btnLoading = true;
      var hintRef = db.collection("users").doc(auth.currentUser.uid);
      hintRef
        .update({
          "uiConfig.hints": FieldValue.arrayUnion(hintID),
        })
        .then(() => {
          console.log("Hint successfully dismissed!");
          this.alert = false;
          this.btnLoading = false;
          this.$store.dispatch(`${USER}${GET_USER_PROFILE}`, {
            uid: auth.currentUser.uid,
            allowRedirect: false,
          });
          // Analytics event -> hint_closed(hintID)
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
