<template>
  <v-chip
    :color="`${getMaterialColor(color)} lighten-4`"
    :label="label"
    :class="[
      'font-weight-medium',
      `${getMaterialColor(color)}--text `,
      'text--darken-4',
    ]"
    :small="small"
    :x-small="xSmall"
  >
    <v-icon
      v-if="iconLeft"
      left
      :small="small || smallIcon"
      :color="`${getMaterialColor(color)} darken-4`"
    >
      mdi-{{ iconLeft }}
    </v-icon>
    <span class="font-weight-medium">{{ text }}</span>
    <v-icon
      v-if="iconRight"
      right
      :small="small || smallIcon"
      :color="`${getMaterialColor(color)} darken-4`"
    >
      mdi-{{ iconRight }}
    </v-icon>
  </v-chip>
</template>

<script>
export default {
  name: "data-chip",
  props: {
    iconRight: {
      type: String,
      required: false,
    },
    iconLeft: {
      type: String,
      required: false,
    },
    label: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false,
      default: "grey",
    },
    text: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    smallIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
        case "#F44336":
        case "red":
          return "red";
        case "#E91E63FF":
        case "#E91E63":
        case "pink":
          return "pink";
        case "#9C27B0FF":
        case "#9C27B0":
        case "purple":
          return "purple";
        case "#673AB7FF":
        case "#673AB7":
        case "deep-purple":
          return "deep-purple";
        case "#3F51B5FF":
        case "#3F51B5":
        case "indigo":
          return "indigo";
        case "#2196F3FF":
        case "#2196F3":
        case "blue":
          return "blue";
        case "#03A9F4FF":
        case "#03A9F4":
        case "light-blue":
          return "light-blue";
        case "#00BCD4FF":
        case "#00BCD4":
        case "cyan":
          return "cyan";
        case "#009688FF":
        case "#009688":
        case "teal":
          return "teal";
        case "#4CAF50FF":
        case "#4CAF50":
        case "green":
          return "green";
        case "#8BC34AFF":
        case "#8BC34A":
        case "light-green":
          return "light-green";
        case "#CDDC39FF":
        case "#CDDC39":
        case "lime":
          return "lime";
        case "#FFEB3BFF":
        case "#FFEB3B":
        case "yellow":
          return "yellow";
        case "#FFC107FF":
        case "#FFC107":
        case "amber":
          return "amber";
        case "#FF9800FF":
        case "#FF9800":
        case "orange":
          return "orange";
        case "#FF5722FF":
        case "#FF5722":
        case "deep-orange":
          return "deep-orange";
        case "#795548FF":
        case "#795548":
        case "brown":
          return "brown";
        case "#607D8BFF":
        case "#607D8B":
        case "blue-grey":
          return "blue-grey";
        case "#9E9E9EFF":
        case "#9E9E9E":
        case "grey":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
