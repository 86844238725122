<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="4000" bottom app
      >{{ this.snackbarText }}
    </v-snackbar>
    <v-card>
      <v-card-title>Datensätze</v-card-title>
      <v-divider></v-divider>
      <v-expansion-panels flat>
        <v-expansion-panel v-for="(item, index) in items" :key="index">
          <v-expansion-panel-header
            ><span
              ><code class="font-weight-bold">{{ item.title }}</code></span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              @click="copyToClipboard(JSON.stringify(item.data, undefined, 4))"
              icon
              small
              class="mr-2"
              ><v-icon small>mdi-content-copy</v-icon></v-btn
            >
            <code>
              <pre class="overflow-x-auto">{{
                JSON.stringify(item.data, undefined, 4)
              }}</pre>
            </code>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CodeDisplay",
  props: {
    items: {
      type: Array, // [{ title: String, data: this.data }]
      required: true,
    },
  },
  data() {
    return {
      show1: false,
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    // Copies a string to the clipboard. Must be called from within an event handler such as click.
    // May return false if it failed, but this is not always
    // possible. Browser support for Chrome 43+, Firefox 42+, Edge and IE 10+.
    // No Safari support, as of (Nov. 2015). Returns false.
    // IE: The clipboard feature may be disabled by an adminstrator. By default a prompt is
    // shown the first time the clipboard is used (per session).
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Fehler beim Kopieren in Zwischenablage.", ex);
          this.snackbarText = "Fehler beim Kopieren in Zwischenablage!";
          this.snackbar = true;
          return false;
        } finally {
          document.body.removeChild(textarea);
          this.snackbarText = "Erfolgreich in Zwischenablage kopiert!";
          this.snackbar = true;
        }
      }
    },
  },
};
</script>

<style></style>
