<template>
  <div class="subheader">
    <v-subheader>
      <v-icon>{{ icon }}</v-icon>
      <strong class="ml-2">{{ title }}</strong>
      <v-spacer></v-spacer>
      <slot name="actions">
        <div v-for="(item, index) in actions" :key="index">
          <div v-if="item.actionStyle === 'textBtn'">
            <div>
              <v-btn
                v-if="
                  item.permission === true || checkPermission(item.permission)
                "
                text
                :disabled="item.disabled"
                :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                class="hidden-sm-and-down"
                @click="item.function"
              >
                <v-icon left>{{ item.icon }}</v-icon>
                <span>{{ item.title }}</span>
              </v-btn>
              <v-btn
                v-if="
                  item.permission === true || checkPermission(item.permission)
                "
                icon
                text
                :disabled="item.disabled"
                :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                class="hidden-md-and-up"
                @click="item.function"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else></div>
        </div>
      </slot>
    </v-subheader>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "subheader",
  props: {
    icon: {
      type: String,
      required: true,
      default: "mdi-circle",
    },
    title: {
      type: String,
      default: "Titel",
    },
    actions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    checkPermission(item) {
      //v-if="checkPermission(item.permission)"
      return this.$store.getters["organization/checkPermissionByID"](item);
    },
  },
};
</script>
